<template>
  <div class="active-shelf">
    <div class="active-shelf__image">
      <img
        :src="activeShelf ? activeShelf.imageURL || activeShelf.image : ''"
        alt=""
      />
    </div>
    <div class="active-shelf__info">
      <p>
        <strong>Active shelf:</strong>

        <a-tooltip
          placement="top"
          overlayClassName="ant-tooltip--step-nav ant-tooltip--summary"
        >
          <template slot="title">
            {{ activeShelf ? activeShelf.Name || activeShelf.shelfName : "" }}
          </template>
          <u class="cursor-pointer">
            {{ activeShelf ? activeShelf.Name || activeShelf.shelfName : "" }}
          </u>
        </a-tooltip>
      </p>
      <span class="active-shelf__retailer" v-if="showRetailer">
        Retailer: {{ activeShelf ? activeShelf.Retailer : "" }}</span
      >
      <div
        class="active-shelf__info-cta"
        v-if="showEdit"
        @click="$emit('editShelf')"
      >
        Edit
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActiveShelf",
  components: {},
  props: {
    activeShelf: {
      type: Object,
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
    showRetailer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
  created() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.active-shelf {
  padding: 10px;
  background: #fbfafa;
  margin-bottom: 20px;
  display: flex;
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  align-items: center;
  &__image {
    width: 68px;
    min-width: 68px;
    height: 52px;
    position: relative;
    overflow: hidden;
    img {
      max-width: 100%;
      border-radius: 3px;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__info {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    p {
      margin: 0;
      font-size: 13px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      strong {
        display: block;
        line-height: 14px;
        margin-bottom: 1px;
      }
    }
  }
  &__info-cta {
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
  }
  &__retailer {
    font-size: 13px;
    line-height: 14px;
    padding-top: 1px;
  }
}
</style>
