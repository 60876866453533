const config = {
  settingsNextRoute: "stimuli-custom",
  progressBarSteps: [
    {
      title: "General Settings",
      icon: "/images/icons/config.svg",
      index: 1,
      route: "pack-test-custom-setting",
    },
    {
      title: "Stimuli",
      icon: "/images/icons/stimuli.svg",
      index: 2,
      route: "stimuli-custom",
    },
    {
      title: "Brand Strategy",
      icon: "/images/icons/video3.svg",
      index: 3,
      route: "communication-objective-custom",
    },
    {
      title: "Launch",
      icon: "/images/icons/launch.svg",
      index: 6,
      route: "pack-test-launch",
    },
  ],
};

export default config;
