const config = {
  surveyTypeID: 102,
  surveyTitle: "Reticle Ad Test",
  surveyNamePlaceholder: "eg: Reticle Video Ads Test",
  videoSettingsNextRoute: "vid-reticle-creative",
  creativeUploadPevRoute: "vid-reticle-setting",
  creativeUploadNextRoute: "vid-reticle-brand-strategy",
  personaNextRoute: "vid-reticle-key-metrics",
  personaPrevRoute: "vid-reticle-creative",
  keyMetricsNextRoute: "vid-reticle-launch",
  keyMetricsPrevRoute: "vid-reticle-brand-strategy",
  launchPrevRoute: "vid-reticle-key-metrics",
  progressBarSteps: [
    {
      title: "General Settings",
      icon: "/images/icons/config.svg",
      index: 1,
      route: "vid-reticle-setting",
    },
    {
      title: "Creative",
      icon: "/images/icons/video2.svg",
      index: 2,
      route: "vid-reticle-creative",
    },
    {
      title: "Brand Strategy",
      icon: "/images/icons/video3.svg",
      index: 3,
      route: "vid-reticle-brand-strategy",
    },
    {
      title: "Key Metrics",
      icon: "/images/icons/video4.svg",
      index: 4,
      route: "vid-reticle-key-metrics",
    },
    {
      title: "Launch",
      icon: "/images/icons/launch.svg",
      index: 6,
      route: "vid-reticle-launch",
    },
  ],
  insertSurveyStimWS: "InsertSurveyStimReticle",
};

export default config;
