const config = {
  surveyTitle: "YouTube Ad Test",
  videoSettingsNextRoute: "vid-yt-creative",
  creativeUploadPevRoute: "vid-yt-setting",
  creativeUploadNextRoute: "vid-yt-brand-strategy",
  personaNextRoute: "vid-yt-key-metrics",
  personaPrevRoute: "vid-yt-creative",
  keyMetricsNextRoute: "vid-yt-launch",
  keyMetricsPrevRoute: "vid-yt-brand-strategy",
  launchPrevRoute: "vid-yt-key-metrics",
  progressBarSteps: [
    {
      title: "General Settings",
      icon: "/images/icons/config.svg",
      index: 1,
      route: "vid-yt-setting",
    },
    {
      title: "Creative",
      icon: "/images/icons/video2.svg",
      index: 2,
      route: "vid-yt-creative",
    },
    {
      title: "Brand Strategy",
      icon: "/images/icons/video3.svg",
      index: 3,
      route: "vid-yt-brand-strategy",
    },
    {
      title: "Key Metrics",
      icon: "/images/icons/video4.svg",
      index: 4,
      route: "vid-yt-key-metrics",
    },
    {
      title: "Launch",
      icon: "/images/icons/launch.svg",
      index: 6,
      route: "vid-yt-launch",
    },
  ],
  insertSurveyStimWS: "InsertSurveyStimYoutube",
};

export default config;
