<template>
  <div class="selected-products">
    <div class="selected-products__image">
      <ul v-for="item in selectedProducts" :key="item.zoneId">
        <li>
          <font-awesome-icon icon="check" />
          {{ item.productName || item.ProductName }}
        </li>
      </ul>
    </div>
    <div class="selected-products__info" v-if="selectedProducts !== null">
      <p>
        <strong>Selected products</strong>You have selected
        <a-tooltip
          placement="top"
          overlayClassName="ant-tooltip--step-nav ant-tooltip--summary"
        >
          <template slot="title">
            <ul
              v-for="item in selectedProducts"
              :key="item.zoneId"
              class="list-reset list-type-disc pl-10"
            >
              <li>{{ item.productName || item.ProductName }}</li>
            </ul>
          </template>
          <u class="cursor-pointer">
            {{ selectedProducts.length }} product<span
              v-if="selectedProducts.length > 1"
              >s</span
            ></u
          > </a-tooltip
        >.
      </p>
      <div
        class="selected-products__info-cta"
        @click="$emit('editSelectedProducts')"
        v-if="showEdit"
      >
        Edit
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectedProducts",
  components: {},
  props: {
    selectedProducts: {
      type: Array,
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
  created() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.selected-products {
  padding: 10px;
  background: #fbfafa;
  margin-bottom: 20px;
  display: flex;
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  align-items: center;
  &__image {
    width: 68px;
    height: 52px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5px;
    background: white;
    border: 1px solid #ececec;
    border-radius: 3px;
    ul {
      margin: 0;
      padding: 0;
      li {
        font-size: 9px;
        position: relative;
        padding-left: 2px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &::after {
          position: absolute;
          left: 0;
          top: 2px;
          width: 9px;
          height: 9px;
          background: #1890ff;
          border-radius: 2px;
          content: "";
        }
        svg {
          width: 5px;
          position: relative;
          z-index: 1;
          color: #fff;
          margin-right: 2px;
        }
      }
    }
  }
  &__info {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    p {
      margin: 0;
      font-size: 13px;
      strong {
        display: block;
        line-height: 14px;
        margin-bottom: 1px;
      }
    }
  }
  &__info-cta {
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.ant-tooltip {
  &--summary {
    max-width: 400px;
  }
}
</style>
